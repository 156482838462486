import React from "react"
import { Link } from "gatsby"
import "./footer.scss"

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="layout-container --no-padding">
          <div id="footer-row" className="has-padding-x">
            <Link to="/imprint/">Impressum</Link>
            <Link to="/privacy/">Datenschutz</Link>
          </div>
        </div>
      </footer>
    </>
  )
}
