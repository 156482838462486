import React from "react"
import "./pictures.scss"

export default function Pictures() {
  return (
    <>
      <section id="pictures">
        <div id="picture-laptop" className="col">
          <span className="col-spacer"></span>
          <div className="overlay --blue-jeans"></div>
        </div>
        <div id="picture-couch" className="col">
          <span className="col-spacer"></span>
          <div className="overlay --tufts-blue"></div>
        </div>
        <div id="picture-desk" className="col">
          <span className="col-spacer"></span>
          <div className="overlay --slate-blue"></div>
        </div>
      </section>
    </>
  )
}
