import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import AppStoreBadge from "../assets/images/svg/download-on-the-app-store-de-black.svg"
import "./portfolio.scss"

export default function Portfolio() {
  return (
    <section id="portfolio">
      <div className="layout-container">

        <div id="portfolio-grid">

          <div className="portfolio-item">
            <StaticImage className="portfolio-banner"
                         src="../assets/images/app-banner-wuerfel-100@2x.png"
                         alt="App-Banner iOS Würfel 100"
                         title="App Würfel 100 ist jetzt im App Store verfügbar"
            />
            <h3 className="portfolio-label">Würfel 100</h3>
            <p className="portfolio-subtitle">
              Würfel 100 ist ein unkompliziertes Würfelspiel mit 1 Würfel. Durch
              sein einfaches Regelwerk eignet es sich perfekt als Partyspiel z.B.
              in der Kneipe oder in geselliger Runde mit Freunden.
            </p>
            <div className="portfolio-cta">
              <a href="https://apple.co/3r9mpwf" target="_blank" rel="noreferrer">
                <AppStoreBadge />
              </a>
            </div>
          </div>

          <div className="portfolio-item">
            <StaticImage className="portfolio-banner"
                         src="../assets/images/plugin-banner-plentymarkets@2x.png"
                         alt="Banner Versand-Plugin GO! Express für plentymarkets"
                         title="GO! Express Plugin ist jetzt verfügbar"
            />
            <h3 className="portfolio-label">GO! Express Plugin</h3>
            <p className="portfolio-subtitle">
              Versand-Plugin zur Anbindung des Logistikdienstleisters GO! Express an
              das plentymarkets E-Commerce ERP.
              Über die integrierte Schnittstelle können Sendungen auf einfache Art und Weise
              angemeldet werden.
            </p>
            <div className="portfolio-cta">
              <a href="https://github.com/mariokehl/plugin-shipping-goexpress" className="custom-underline" target="_blank" rel="noreferrer">
                Anzeigen auf: <strong>GitHub</strong> &#x2197;
              </a>
              <span className="eol-badge" title="Kein Plugin-Support!">EOL</span>
            </div>
          </div>

          <div className="portfolio-item">
            <StaticImage className="portfolio-banner"
                         src="../assets/images/plugin-banner-plentymarkets@2x.png"
                         alt="Banner Versand-Plugin eCourier für plentymarkets"
                         title="eCourier Plugin ist jetzt verfügbar"
            />
            <h3 className="portfolio-label">eCourier Plugin</h3>
            <p className="portfolio-subtitle">
              Versand-Plugin zur Anbindung des Transport-Management-Systems eCourier von bamboo Software an
              das plentymarkets E-Commerce ERP.
              Über die integrierte Schnittstelle können Sendungen auf einfache Art und Weise
              angemeldet werden.
            </p>
            <div className="portfolio-cta">
              <a href="https://github.com/mariokehl/plugin-shipping-ecourier" className="custom-underline" target="_blank" rel="noreferrer">
                Anzeigen auf: <strong>GitHub</strong> &#x2197;
              </a>
              <span className="eol-badge" title="Kein Plugin-Support mehr!">EOL</span>
            </div>
          </div>

          <div className="portfolio-item">
            <StaticImage className="portfolio-banner"
                         src="../assets/images/shop-banner-plentymarkets@2x.png"
                         alt="Banner Shop-Erweiterung Gratiszugabe im Warenkorb anzeigen für plentymarkets"
                         title="Shop-Erweiterung Gratiszugabe im Warenkorb anzeigen ist jetzt verfügbar"
            />
            <h3 className="portfolio-label">Gratiszugabe im Warenkorb</h3>
            <p className="portfolio-subtitle">
              Shop-Erweiterung zur Anzeige von Gratisartikeln, Geschenken oder Produktproben
              ab einem festgelegtem Brutto-Warenkorbwert im Warenkorb des plentymarkets E-Commerce ERP.
              Mit Beilagen &amp; kostenlosen Produkten können Online-Händler:innen höhere Umsätze
              in ihrem plentyShop generieren.
            </p>
            <div className="portfolio-cta">
              <a href="https://github.com/mariokehl/plugin-template-checkoutgoodie" className="custom-underline" target="_blank" rel="noreferrer">
                Anzeigen auf: <strong>GitHub</strong> &#x2197;
              </a>
              <span className="eol-badge" title="Kein Plugin-Support mehr!">EOL</span>
            </div>
          </div>

          <div className="portfolio-item">
            <StaticImage className="portfolio-banner"
                         src="../assets/images/shop-banner-plentymarkets@2x.png"
                         alt="Banner Shop-Erweiterung Versandkostenfrei ab Warenwert erreichen für plentymarkets"
                         title="Shop-Erweiterung Versandkostenfrei ab Warenwert erreichen ist jetzt verfügbar"
            />
            <h3 className="portfolio-label">Versandkostenfrei ab Warenwert</h3>
            <p className="portfolio-subtitle">
              Shop-Erweiterung zur Visualisierung des noch fehlenden Warenwerts bis zum Gratisversand
              im Warenkorb &amp; Checkout des plentymarkets E-Commerce ERP.
              Mit dieser Anzeige in Echtzeit kann der durchschnittliche Warenkorbwert
              der Kund:innen erhöht werden.
            </p>
            <div className="portfolio-cta">
              <a href="https://github.com/mariokehl/plugin-template-freeshippinggoal" className="custom-underline" target="_blank" rel="noreferrer">
                Anzeigen auf: <strong>GitHub</strong> &#x2197;
              </a>
              <span className="eol-badge" title="Kein Plugin-Support mehr!">EOL</span>
            </div>
          </div>

          <div className="portfolio-item">
            <StaticImage className="portfolio-banner"
                         src="../assets/images/shop-banner-plentymarkets@2x.png"
                         alt="Banner Shop-Erweiterung Samstagszustellung buchen für plentymarkets"
                         title="Shop-Erweiterung Samstagszustellung buchen ist jetzt verfügbar"
            />
            <h3 className="portfolio-label">Samstagszustellung buchen</h3>
            <p className="portfolio-subtitle">
              Mit dieser Shop-Erweiterung können Kund:innen zusätzlich zum regulären Versand
              ganz einfach in der Kasse einen Liefertermin für den Expressversand auswählen.
              Der Aufpreis für den Fixtermin wird anschließend automatisch addiert und das
              Wunschdatum an den Versanddienstleister weitergegeben.
            </p>
            <div className="portfolio-cta">
              <a href="https://github.com/mariokehl/plugin-template-saturdaydelivery" className="custom-underline" target="_blank" rel="noreferrer">
                Anzeigen auf: <strong>GitHub</strong> &#x2197;
              </a>
              <span className="eol-badge" title="Kein Plugin-Support mehr!">EOL</span>
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}
