import React, { useState } from "react"
import TextLoop from "react-text-loop"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundVideo from "../assets/videos/intro.mp4"
import VideoPoster from "../assets/images/intro-fallback-video-image.png"
import Logo from "../assets/images/svg/logo_notext.svg"
import IconExpandMore from "../assets/images/svg/expand_more-white-48dp.svg"
import "./intro.scss"

export default function Intro() {
  const [isActive, setActive] = useState(false)

  const handleToggle = () => {
    setActive(!isActive)
  }

  return (
    <>
      <section id="intro">
        <StaticImage className="intro-video-fallback-img"
                     src="../assets/images/intro-fallback-video-image.png"
                     alt="Mario Kehl – Spezialist für TYPO3 & App-Entwicklung in Kassel"
                     placeholder="blurred"
                     objectPosition="30% 50%"
                     loading="eager"
        />
        <video id="intro-video" autoPlay muted playsInline loop>
          <source src={BackgroundVideo} poster={VideoPoster} type="video/mp4" />
        </video>
        <nav id="main-navigation" className={isActive ? "active" : ""}>
          <ul id="navigation-top">
            <li>
              <Link to="/">Intro</Link>
            </li>
            <li>
              <a href="#profile">Profil</a>
            </li>
            <li>
              <a href="#portfolio">Portfolio</a>
            </li>
            <li>
              <a href="#contact">Kontakt</a>
            </li>
            <li>
              <Link to="/imprint/" className="visible-lg">
                Impressum
              </Link>
            </li>
            <li>
              <Link to="/privacy/" className="visible-lg">
                Datenschutz
              </Link>
            </li>
          </ul>
          <ul id="navigation-bottom" className="hidden-lg">
            <li>
              <Link to="/imprint/">Impressum</Link>
            </li>
            <li>
              <Link to="/privacy/">Datenschutz</Link>
            </li>
          </ul>
        </nav>
        <div id="intro-overlay">
          <header>
            <div id="header-left">
              <button
                onClick={handleToggle}
                className={isActive ? "nav-active" : ""}
              >
                <span id="nav-toggler-icon">
                  <span id="nav-toggler-text">Menu</span>
                </span>
              </button>
            </div>
            <div id="header-logo">
              <Logo />
            </div>
            {/*
            <div id="header-right">
              <a
                href="https://www.xing.com/profile/Mario_Kehl2"
                target="_blank"
                rel="noreferrer"
              >
                <img id="xing-logo" src={XingLogo} alt="Mario Kehl bei XING" />
              </a>
            </div>
            */}
          </header>
          <div id="text-slogan">
            <span>Freelancer</span>
            <div id="areas-of-expertise">
              <TextLoop>
                <span>Next Generation Internet</span>
                <span>Cloud Computing</span>
                <span>E-Commerce</span>
                <span>Machine Learning &amp; KI</span>
                <span>Mobile / Internet of Things</span>
                <span>Social Media Plattformen</span>
                <span>Blockchain &amp; P2P</span>
              </TextLoop>
            </div>
          </div>
          <div id="icon-expand" className="hidden-lg">
            <IconExpandMore />
          </div>
        </div>
      </section>
    </>
  )
}
