import React from "react"
import Arrow from "../assets/images/svg/cta_arrow.svg"
import "./contact.scss"

export default function Contact() {
  return (
    <>
      <section id="contact">
        <div className="layout-container --no-padding">
          <div id="cta-wrapper">
            <div id="cta-arrow">
              <Arrow />
            </div>
            <div id="cta-text">
              <p>
                Wenn Sie mehr darüber erfahren möchten, wie ich Ihnen helfen
                kann, oder wenn Sie Fragen haben, zögern Sie bitte nicht, mich
                zu kontaktieren. Ich freue mich darauf, mit Ihnen
                zusammenzuarbeiten und Ihnen bei Ihren IT-Bedürfnissen zu
                helfen.
              </p>
              <p>
                Anfragen zu Stundensatz und freien Kapazitäten gerne im Rahmen
                einer <a href="mailto:bureau@mariokehl.com">E-Mail</a> oder via{" "}
                <a
                  href="https://www.xing.com/profile/Mario_Kehl2"
                  target="_blank"
                  rel="noreferrer"
                >
                  XING
                </a>
                .
              </p>
            </div>
          </div>
          <div id="contact-info" className="has-padding">
            <a href="mailto:bureau@mariokehl.com">bureau@mariokehl.com</a>
            <a href="tel:+4956149972560">+49 561 49972560</a>
          </div>
        </div>
      </section>
    </>
  )
}
