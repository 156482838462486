import React from "react"
import "./profile.scss"

export default function Profile() {
  return (
    <main id="profile" role="main">
      <div className="layout-container">
        <h1>
          <span id="waving-hand" role="img" aria-label="Winkende Hand">
            &#x1f44b;
          </span>{" "}
          Ich bin's, Mario!
        </h1>
        <p>
          Ein erfahrener IT-Experte mit über zwei Jahrzehnten in der Welt der
          Informationstechnologie. Ich bin leidenschaftlich daran interessiert,
          innovative Lösungen zu entwickeln, die Unternehmen dabei helfen, ihre
          Ziele zu erreichen und sich in einer zunehmend digitalisierten Welt
          erfolgreich zu positionieren.
        </p>
        <div id="profile-grid">
          <div id="profile--experience" className="gray-box">
            <h2>Erfahrung</h2>
            <p>
              Mit mehr als 20 Jahren Erfahrung in der IT-Branche habe ich ein
              breites Spektrum an Fähigkeiten und Kenntnissen in verschiedenen
              Bereichen der Informationstechnologie entwickelt. Während meiner
              Karriere habe ich in verschiedenen Positionen gearbeitet,
              angefangen von der Systemadministration und Netzwerkverwaltung bis
              hin zur Softwareentwicklung und IT-Strategieberatung. Diese
              vielfältigen Erfahrungen haben mir geholfen, ein tiefes
              Verständnis für die sich ständig weiterentwickelnde IT-Landschaft
              zu entwickeln.
            </p>
          </div>
          <div id="profile--passion" className="gray-box">
            <h2>Leidenschaft</h2>
            <p>
              Meine Leidenschaft liegt darin, Unternehmen dabei zu unterstützen,
              ihre IT-Infrastruktur zu optimieren, Prozesse zu automatisieren
              und innovative Technologien zu nutzen, um Wachstum und Effizienz
              zu fördern. Ich glaube fest daran, dass die richtige IT-Strategie
              und -Implementierung einen signifikanten Unterschied in der
              Wettbewerbsfähigkeit eines Unternehmens ausmachen können.
            </p>
          </div>
          <div id="profile--sectors" className="gray-box">
            <h2>Branchen</h2>
            <ul className="list-inline">
              <li>🏦 Finanzen</li>
              <li>🏥 Gesundheitswesen</li>
              <li>🛍️ Einzelhandel</li>
              <li>🔐 Sicherheit</li>
              <li>🧪 Forschung</li>
              <li>🛞 Automobilindustrie</li>
              <li>🚚 Transport &amp; Logistik</li>
              <li>🎲 Spiele</li>
              <li>📚 Bildung</li>
            </ul>
          </div>
          <div id="profile--services" className="gray-box">
            <h2>Dienstleistungen</h2>
            <p>
              Zu dem Umfang an Dienstleistungen, die ich als Generalist anbiete, zählen:
            </p>
            <ul className="list-check">
              <li>IT-Beratung und Strategieentwicklung</li>
              <li>Netzwerk- und Systemadministration</li>
              <li>Softwareentwicklung und Anpassung</li>
              <li>Cybersecurity- und Datenschutzlösungen</li>
              <li>Cloud-Computing und Virtualisierung</li>
            </ul>
            <p>
              Ich bin bestrebt, maßgeschneiderte Lösungen anzubieten, die den
              einzigartigen Anforderungen jedes Kunden gerecht werden. Mit
              meiner Expertise und meinem Engagement für Exzellenz stehe ich
              Ihnen gerne zur Seite, um Ihre IT-Herausforderungen zu bewältigen.
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}
